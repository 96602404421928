import React, {FC} from 'react';
import Layout from '../templates/Layout';
import UninstallRoot from '../components/Uninstall';

const Uninstall: FC<void> = () => {
    return (
        <Layout>
            <UninstallRoot />
        </Layout>
    );
};

export default Uninstall;
